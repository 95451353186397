// axios
import axios from 'axios'
import Vue from 'vue'
import { Message } from '@prettyy/ui'

class EventEmitter {
  constructor() {
    this.event = {}
  }

  on(type, cbres, cberr) {
    if (this.event[type]) {
      this.event[type].push({ cbres, cberr })
    } else {
      this.event[type] = [{ cbres, cberr }]
    }
  }

  emit(type, res, ansType) {
    if (!this.event[type]) {
      return
    }

    this.event[type].forEach(cbArr => {
      if (ansType === 'resolve') {
        if (typeof cbArr[0] === 'function') cbArr[0](res)
      } else if (typeof cbArr[1] === 'function') cbArr[1](res)
    })
  }
}

// 根据请求生成对应的key

function generateReqKey(config, hash) {
  const {
    method, url, params, data,
  } = config

  return [method, url, JSON.stringify(params), JSON.stringify(data), hash].join('&')
}

// 判断是否为上传请求
const isFileUploadApi = config => Object.prototype.toString.call(config.data) === '[object FormData]'

// 存储已发送但未响应的请求

const pendingRequest = new Set()

// 发布订阅容器

const ev = new EventEmitter()

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  defaultTips: false, // 状态码非200、400、401时默认不提示错误
})

axiosIns.interceptors.request.use(
  async config => {
    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    // Do something before request is sent
    const { hash } = window.location

    // 生成请求Key

    const reqKey = generateReqKey(config, hash)

    if (!isFileUploadApi(config) && pendingRequest.has(reqKey)) {
      // 如果是相同请求,在这里将请求挂起，通过发布订阅来为该请求返回结果

      // 这里需注意，拿到结果后，无论成功与否，都需要return Promise.reject()来中断这次请求，否则请求会正常发送至服务器
      let res = null

      try {
        // 接口成功响应

        res = await new Promise((resolve, reject) => {
          ev.on(reqKey, resolve, reject)
        })

        // eslint-disable-next-line
        return Promise.reject({
          type: 'limiteResSuccess',
          val: res,
        })
      } catch (limitFunErr) {
        // 接口报错
        // eslint-disable-next-line
        return Promise.reject({
          type: 'limiteResError',
          val: limitFunErr,
        })
      }
    } else {
      // 将请求的key保存在config
      // eslint-disable-next-line
      config.pendKey = reqKey
      pendingRequest.add(reqKey)
    }

    return config
  },
  error => Promise.reject(error),
)

// 接口响应成功
const handleSuccessResponseLimit = response => {
  const reqKey = response.config.pendKey
  if (pendingRequest.has(reqKey)) {
    let x = null

    try {
      x = JSON.parse(JSON.stringify(response))
    } catch (e) {
      x = response
    }
    pendingRequest.delete(reqKey)

    ev.emit(reqKey, x, 'resolve')
    delete ev.reqKey
  }
}

// 接口响应失败
const handleErrorResponseLimit = (error = { }) => {
  if (error.type && error.type === 'limitResSuccess') {
    return Promise.resolve(error.val)
  } if (error.type && error.type === 'limitResError') {
    return Promise.reject(error.val)
  }
  const reqKey = error.config.pendKey
  if (pendingRequest.has(reqKey)) {
    let x = null
    try {
      x = JSON.parse(JSON.stringify(error))
    } catch (e) {
      x = error
    }
    pendingRequest.delete(reqKey)
    ev.emit(reqKey, x, 'reject')
    delete ev.reqKey
  }

  return Promise.reject(error)
}

// Add request/response interceptor
axiosIns.interceptors.response.use(
  response => {
    // 将拿到的结果发布给其他相同的接口
    handleSuccessResponseLimit(response)

    if ([401, 400].includes(response.data.status)) {
      localStorage.removeItem('accessToken')

      window.location.href = '/login'

      return response
    }

    if (response.data.status !== 200 && response.config.defaultTips) {
      Message.error(response.data.message)
    }

    return response
  },
  error => {
    const { response } = error

    if (response && response.data) {
      Message.error(response.data.message)
    }

    return handleErrorResponseLimit(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
