import { VCard } from 'vuetify/lib/components/VCard';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"table-wrap",staticStyle:{"display":"flex","flex-direction":"column","box-shadow":"none","height":"100%"}},[_c('vxe-table',_vm._g(_vm._b({directives:[{name:"loadmore",rawName:"v-loadmore",value:(_vm.loadMorePage),expression:"loadMorePage"}],ref:"tableRef",staticClass:"mytable-scrollbar",class:{ 'auto-height': _vm.isHeightAuto },attrs:{"align":null,"data":_vm.rows,"loading-config":{icon: 'vxe-icon-indicator roll', text: '加载中...'},"footer-method":_vm.footerMethod,"filter-config":{iconNone: 'vxe-icon-caret-down', iconMatch: 'vxe-icon-caret-down'},"row-config":Object.assign({}, {isCurrent: true, isHover: true}, _vm.rowConfig),"empty-text":"暂无数据","show-overflow":"","height":"auto","show-footer":_vm.$attrs.showFooter,"min-height":_vm.height},on:{"cell-click":_vm.cellClickEvent,"checkbox-all":_vm.selectChange,"checkbox-change":_vm.selectChange,"radio-change":_vm.radioChange}},'vxe-table',_vm.$attrs,false),_vm.$listeners),[_vm._v(" > "),_vm._l(([{text: '', width: 1, fixed: 'left', formatter: function () { return ''; }} ].concat( _vm.columns)),function(column){return [_c('vxe-column',_vm._b({key:column.value,attrs:{"show-header-overflow":column.showHeaderOverflow === false ? column.showHeaderOverflow : true,"show-overflow":column.showOverflow === false ? column.showOverflow : true,"show-footer-overflow":column.showFooterOverflow === false ? column.showFooterOverflow : true,"field":column.value,"title":column.text,"min-width":column.minWidth || 100,"formatter":column.formatter || (function (ref) {
	var cellValue = ref.cellValue;

	return (cellValue || cellValue ===0 ? cellValue : '-');
})},scopedSlots:_vm._u([(column.slots)?{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._t(column.slots,null,{"row":row,"column":column})]}}:null,(column.contentSlots)?{key:"content",fn:function(ref){
var row = ref.row;
return [_vm._t(column.contentSlots,null,{"row":row,"column":column})]}}:null,(column.slotsFooter)?{key:"footer",fn:function(ref){
var items = ref.items;
var _columnIndex = ref._columnIndex;
return [_vm._t(column.slotsFooter,null,{"value":items[_columnIndex],"column":column})]}}:null,(column.slotsHeader)?{key:"header",fn:function(){return [_vm._t(column.slotsHeader,null,{"column":column})]},proxy:true}:null],null,true)},'vxe-column',Object.assign({}, column),false))]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }