<script>
import { watch, ref, computed } from '@vue/composition-api'
import { throttle } from '@core/utils/form'

export default {
  name: 'XwTable',
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    footerData: {
      type: Array,
      default: () => [],
    },
    needLoadMore: {
      type: Boolean,
      default: false,
    },
    rowConfig: {
      type: Object,
      default: () => {},
    },
    height: {
      type: [Number, String],
      default: 580,
    },
  },
  setup(props, { emit }) {
    function loadMore() {
      // 是否需要分页
      if (!props.needLoadMore) return
      console.log('loadMore')
      emit('loadmore')
    }

    const loadMorePage = throttle(loadMore, 1000)

    function footerMethod() {
      return [props.footerData]
    }

    function cellClickEvent({ row, column }) {
      emit('cell-click', { row, column })
    }

    function getFilters(column) {
      return props.rows.map(item => ({
        label: item[column.value],
        value: item[column.value],
      }))
    }

    const filterValue = ref('')

    function filterMethod({ value }) {
      filterValue.value = [value]
    }

    watch(filterValue, val => {
      emit('filter', val)
    })

    const tableRef = ref()

    function getCheckboxRecords() {
      const selectRecords = tableRef.value.getCheckboxRecords()

      return selectRecords
    }

    function getRadioRecord() {
      return tableRef.value.getRadioRecord()
    }

    // 清空选中数据数据
    function clearCheckboxRow() {
      tableRef.value.clearCheckboxRow()
    }

    // 选中数据数据变化触发
    function selectChange() {
      emit('selectChange', getCheckboxRecords())
    }

    function radioChange() {
      emit('radioChange', getRadioRecord())
    }

    function setSelectRow(rows, checked) {
      if (tableRef.value) {
        tableRef.value.setCheckboxRow(rows, checked)
      }
    }

    function setAllRowExpand(checked) {
      if (tableRef.value) {
        tableRef.value.setAllRowExpand(checked)
      }
    }

    const isHeightAuto = computed(() => props.height === 'auto')

    function getTableRefDom() {
      return tableRef.value
    }

    return {
      loadMorePage,
      footerMethod,
      cellClickEvent,
      getFilters,
      filterMethod,
      tableRef,
      getCheckboxRecords,
      clearCheckboxRow,
      selectChange,
      setSelectRow,
      getTableRefDom,
      getRadioRecord,
      radioChange,
      setAllRowExpand,
      isHeightAuto,
    }
  },
}
</script>

<template>
  <v-card
    class="table-wrap"
    style="display: flex; flex-direction: column; box-shadow: none; height: 100%;"
  >
    <vxe-table
      v-bind="$attrs"
      ref="tableRef"
      v-loadmore="loadMorePage"
      :align="null"
      :data="rows"
      :loading-config="{icon: 'vxe-icon-indicator roll', text: '加载中...'}"
      :footer-method="footerMethod"
      :filter-config="{iconNone: 'vxe-icon-caret-down', iconMatch: 'vxe-icon-caret-down'}"
      :row-config="{isCurrent: true, isHover: true, ...rowConfig}"
      empty-text="暂无数据"
      class="mytable-scrollbar"
      :class="{ 'auto-height': isHeightAuto }"
      show-overflow
      height="auto"
      :show-footer="$attrs.showFooter"
      :min-height="height"
      v-on="$listeners"
      @cell-click="cellClickEvent"
      @checkbox-all="selectChange"
      @checkbox-change="selectChange"
      @radio-change="radioChange"
    >
      >
      <template v-for="column in [{text: '', width: 1, fixed: 'left', formatter: () => ''},...columns]">
        <vxe-column
          v-bind="{...column}"
          :key="column.value"
          :show-header-overflow="column.showHeaderOverflow === false ? column.showHeaderOverflow : true"
          :show-overflow="column.showOverflow === false ? column.showOverflow : true"
          :show-footer-overflow="column.showFooterOverflow === false ? column.showFooterOverflow : true"
          :field="column.value"
          :title="column.text"
          :min-width="column.minWidth || 100"
          :formatter="column.formatter || (({cellValue}) => (cellValue || cellValue ===0 ? cellValue : '-'))"
        >
          <template
            v-if="column.slots"
            #default="{ row }"
          >
            <slot
              :name="column.slots"
              :row="row"
              :column="column"
            ></slot>
          </template>
          <template
            v-if="column.contentSlots"
            #content="{ row }"
          >
            <slot
              :name="column.contentSlots"
              :row="row"
              :column="column"
            ></slot>
          </template>
          <template
            v-if="column.slotsFooter"
            #footer="{items, _columnIndex}"
          >
            <slot
              :name="column.slotsFooter"
              :value="items[_columnIndex]"
              :column="column"
            ></slot>
          </template>
          <template
            v-if="column.slotsHeader"
            #header
          >
            <slot
              :name="column.slotsHeader"
              :column="column"
            ></slot>
          </template>
        </vxe-column>
      </template>
    </vxe-table>
  </v-card>
</template>
<style lang="scss">
.vxe-table--tooltip-wrapper.theme--dark{
    background: rgba(78,89,105,0.9)!important;
  }
.vxe-table--tooltip-wrapper.theme--dark.placement--top .vxe-table--tooltip-arrow, .vxe-table--tooltip-wrapper.theme--dark.placement--top .vxe-table--tooltip-arrow:before{
  border-top-color: rgba(78,89,105,0.9)!important;
}
.vxe-table--tooltip-wrapper.theme--dark.placement--bottom .vxe-table--tooltip-arrow, .vxe-table--tooltip-wrapper.theme--dark.placement--bottom .vxe-table--tooltip-arrow:before{
  border-bottom-color: rgba(78,89,105,0.9)!important;
}
.vxe-table--render-default .is--checked.vxe-cell--checkbox{
  color:rgba(94, 86, 105, 0.68) !important
}
.vxe-checkbox .vxe-checkbox--icon, .vxe-custom--checkbox-option .vxe-checkbox--icon, .vxe-export--panel-column-option .vxe-checkbox--icon, .vxe-table--filter-option .vxe-checkbox--icon, .vxe-table--render-default .vxe-cell--checkbox .vxe-checkbox--icon{
  color:rgba(94, 86, 105, 0.68) !important
}
.is--checked.vxe-radio, .is--checked.vxe-radio .vxe-radio--icon, .vxe-table--render-default .is--checked.vxe-cell--radio, .vxe-table--render-default .is--checked.vxe-cell--radio .vxe-radio--icon{
  color:var(--v-primary-base) !important
}
.vxe-radio:not(.is--disabled):hover .vxe-radio--icon, .vxe-table--render-default .vxe-cell--radio:not(.is--disabled):hover .vxe-radio--icon{
  color:var(--v-primary-base) !important
}
.vxe-table .vxe-sort--asc-btn.sort--active, .vxe-table .vxe-sort--desc-btn.sort--active{
  color: var(--v-primary-base)
}
//.vxe-table td a{
//  color: #606266;
//  text-decoration: none
//}
//.vxe-table .row--hover td a{
// color: rgba(44, 160, 248, 1)
//}
.vxe-table td .vxe-cell a.text--primary{
  color: var(--v-primary-base)!important
}
.vxe-table--render-default, .vxe-table .vxe-table--header-wrapper{
  color: rgba(0,0,0, 0.8)!important;
}

.vxe-header--row th:last-child .vxe-cell, .vxe-body--row td:last-child .vxe-cell{
  padding-right: 20px;
}
.vxe-cell--checkbox.is--disabled{
  opacity: 0.5;
}
.vxe-table--render-default .vxe-table--fixed-left-wrapper, .vxe-table--render-default .vxe-table--fixed-right-wrapper{
  z-index: 4!important;
}
.expand.table-wrap{
  background: #F6F7F8;
  .vxe-table--render-wrapper{
    border-left: 1px solid #E7ECF0;
    border-right: 1px solid #E7ECF0;
  }
  .vxe-table--header, .vxe-body--row{
    background: #F6F7F8;
  }
}
.vxe-table--render-default .vxe-table--expanded .vxe-table--expand-btn{
    font-size: 18px;
    margin-top: 7px;
  }
.auto-height .vxe-table--body-wrapper.fixed-left--wrapper{
  padding-bottom: 10px!important;
}
.auto-height .vxe-table--body-wrapper.fixed-right--wrapper{
  padding-bottom: 10px!important;
}
.vxe-header--row .vxe-header--column:first-child .vxe-cell{
  padding-left: 20px!important;
  box-sizing: content-box;
}
.vxe-body--row .vxe-body--column:first-child .vxe-cell{
  padding-left: 20px!important;
  box-sizing: content-box;
}

</style>
<style lang="scss" scoped>
.table-wrap{
  ::v-deep .vxe-table--render-default .vxe-table--border-line{
    border: none
  }
  ::v-deep .vxe-table--render-default .vxe-table--footer-wrapper table{
    background-color: #f8f8f9;
    font-weight: bold;

  }
}
/*滚动条整体部分*/
.mytable-scrollbar ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*滚动条的轨道*/
.mytable-scrollbar ::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}
/*滚动条里面的小方块，能向上向下移动*/
.mytable-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  border: 1px solid #F1F1F1;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #A8A8A8;
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}
/*边角，即两个滚动条的交汇处*/
.mytable-scrollbar ::-webkit-scrollbar-corner {
  background-color: #FFFFFF;
}
.theme--dark {
  ::v-deep .vxe-table--footer-wrapper, ::v-deep .vxe-table--render-default, ::v-deep .vxe-table .vxe-table--header-wrapper {
    color: #ada8c3;
  }
  ::v-deep .vxe-table--render-default.border--default .vxe-table--header-wrapper,
  ::v-deep .vxe-table--render-default.border--full .vxe-table--header-wrapper,
  ::v-deep .vxe-table--render-default.border--outer .vxe-table--header-wrapper{
    background-color: #3b355a !important;
  }
  ::v-deep .vxe-table--render-default .vxe-table--footer-wrapper table,
  ::v-deep .vxe-table--render-default .vxe-table--body-wrapper table {
    background-color: transparent;
    border: none
  }
  ::v-deep .vxe-table--render-default.border--default .vxe-header--column,
  ::v-deep .vxe-table--render-default.border--default .vxe-body--column,
  ::v-deep .vxe-table--render-default.border--default .vxe-footer--column,
  ::v-deep .vxe-table--render-default.border--inner .vxe-header--column,
  ::v-deep .vxe-table--render-default.border--inner .vxe-body--column,
  ::v-deep .vxe-table--render-default.border--inner .vxe-footer--column{
    background-image:  linear-gradient(#4b4764, #4b4764)!important;
  }
  ::v-deep .vxe-table-body-background-color{
    background-color: rgba(231, 227, 252, 0.08);
  }
  ::v-deep .vxe-table--border-line, ::v-deep .vxe-table--render-default .vxe-table--footer-wrapper, ::v-deep .vxe-table .vxe-table--header-wrapper .vxe-table--header-border-line{
    border: none
  }
  ::v-deep .vxe-table--render-default .vxe-body--x-space{
    background: none;
  }
  ::v-deep .vxe-table--render-default .vxe-body--row.row--current{
    background-color: var(--vs-dropdown-bg);
  }

  ::v-deep .vxe-table--render-default .vxe-body--row.row--hover{
    background-color: var(--vs-dropdown-bg);
  }
  ::v-deep .vxe-table--fixed-right-wrapper, ::v-deep .vxe-table--fixed-left-wrapper{
    background: rgba(49, 45, 75, 1) !important
  }

  /*滚动条整体部分*/
.mytable-scrollbar ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*滚动条的轨道*/
.mytable-scrollbar ::-webkit-scrollbar-track {
  background-color: rgba(231, 227, 252, 0.08);
}
/*滚动条里面的小方块，能向上向下移动*/
.mytable-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #4b4764;
  border-radius: 5px;
  border: 1px solid #55515E;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #4b4764;
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #4b4764;
}
/*边角，即两个滚动条的交汇处*/
.mytable-scrollbar ::-webkit-scrollbar-corner {
  background-color: rgba(231, 227, 252, 0.08);
}
}
</style>
